import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { TypeAnimation } from "react-type-animation";
import Modal from "www/shared/components/modal/Modal";

export function HomeHeroHeader() {
  return (
    <div className="flex flex-col  items-center lg:items-start relative w-fit min-w-screen">
      <div className="font-inter font-normal sm:text-5xl text-4xl leading-[48px] flex gap-2">
        <p className="text-green-700"> Grow your </p>
        <TypeAnimation
          sequence={["net worth", 4000, "network", 4000]}
          speed={1}
          deletionSpeed={1}
          wrapper="div"
          cursor={true}
          repeat={Infinity}
          className="text-gray-700 font-bold"
        />
      </div>
      <p className="lg:mt-6 mt-3 font-inter text-gray-500 text-base lg:text-xl font-light max-w-[350px] lg:text-left text-center">
        A private network for discovering real estate offerings.
      </p>
    </div>
  );
}

export function HomeHeroFooter() {
  return (
    <>
      <div className="flex gap-x-6 sm:mt-10">
        <Link
          className="text-white font-semibold bg-green-700 hover:bg-green-800 rounded-md flex justify-center items-center gap-x-2 px-6 py-4 shadow-[0px_12px_12px_-6px_rgba(21,128,61,0.24)] group"
          href="/sponsors"
        >
          I&apos;m a sponsor
          <FontAwesomeIcon
            className="mt-[1px] text-sm group-hover:translate-x-1 transition-transform"
            icon={faArrowRight}
          />
        </Link>
        <Link
          className="text-white font-semibold bg-slate-900 rounded-md flex justify-center items-center gap-x-2 px-6 py-4 shadow-[0px_12px_12px_-6px_rgba(21,128,61,0.24)] group"
          href="/investors"
        >
          I&apos;m an investor
          <FontAwesomeIcon
            className="mt-[1px] text-sm group-hover:translate-x-1 transition-transform"
            icon={faArrowRight}
          />
        </Link>
      </div>
    </>
  );
}

export function WaitlistSuccess({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  return (
    <Modal
      widthClasses="w-[512px]"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={null}
      showContinueButton={true}
      showButtonInMiddle={true}
    >
      <div className="h-full flex flex-col  items-center">
        <h2 className="text-green-700 text-4xl leading-10 font-bold mt-8">
          Success!
        </h2>
        <div className="text-2xl leading-8 font-semibold mt-3 text-gray-700">
          You are on the waitlist
        </div>
        <div className="m-6">
          <Image
            className="bg-white rounded-full max-h-[150px]"
            src="/home-images/waitlist.svg"
            alt="Home Hero Picture"
            height={150}
            width={400}
          />
        </div>
        <div className="text-md leading-7 font-normal w-96 text-center text-gray-500">
          <p>
            You are one step closer to growing your network and net worth with
            Elmase.
          </p>

          <p className="mt-6">We will email you when your spot is ready.</p>
        </div>

        <div className="flex-1" />
      </div>
    </Modal>
  );
}
